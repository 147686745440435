$dash-accent: #09548d;
$dash-blue1: #127b90;

$dash-blue2: #06b5d8;
$dash-blue3: #00e6ff;
$dash-blue4: #676a8a;
$dash-blue5: #005493;
//$dash-light-grey1: #e0e0e0;
$dash-light-grey1: #c9c9c9;
$dash-light-grey2: #c1bdbd;
$dash-light-grey3: #f9f8f6;
$dash-light-grey4: #bdbdbd;
$dash-light-grey5: #dedbdb;
$dash-light-grey1-opacity30: #e0e0e04c;

$dash-grey1: #333333;
$dash-grey2: #3b3b3b;
$dash-grey3: #4a4a4a;
$dash-grey4: #5c5a5a;
$dash-grey5: #686868;
$dash-grey6: #707070;
$dash-red: #ff0000;
$dash-dark-red: #9e2121;
$dash-status-blue: #84b4c8;
$dash-status-gray: #cfcfc4;
$dash-status-orange: #fdcf76;
$dash-status-green: #81d381;
$dash-status-pink: #ffa7b6;
$dash-status-red: #e84e45;
$dash-status-blue-body: #dfeef5;
$dash-status-gray-body: #e0e0d8;
$dash-status-orange-body: #fcf0cf;
$dash-status-green-body: #daf5da;
$dash-status-pink-body: #fdcfd7;
$dash-status-red-body: #ffe8e6;
$dash-black: #232323;
// gg
