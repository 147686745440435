.dash-toaster {
  & > div {
    background-color: $dash-grey1;
    color: $dash-light-grey1;
    border-radius: 10px;
    &[class*="success"] {
      background: $dash-status-green;
      color: #fff;
    }
    &[class*="error"] {
      background: $dash-status-red;
      color: #fff;
    }
  }
  div[class*="progress-bar"] {
    background-color: $dash-light-grey1;
  }
}
