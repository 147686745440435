@import './assets/scss/_colors.scss';
@import '../node_modules/@fortawesome/fontawesome-free/css/all.min.css';
//@import "./react-big-calendar.css";

@import '../node_modules/segoe-fonts/segoe-fonts.css';
@import './assets/scss/_utils.scss';
@import './assets/scss/_multiSelect.scss';
@import './assets/scss/_helperClasses.scss';
@import './assets/scss/_statusCards.scss';
@import './assets/scss/_messages.scss';
@import './assets/scss/_toaster.scss';
@import './assets/scss/_calender.scss';
@import './assets/sharedDesign/sharedDesign.scss';
@import './assets/scss/entryPages.scss';
@import './assets/scss/userCard.scss';
@import './assets/scss/table.scss';
@import './components/register/register.style.scss';
@import './assets/scss/_sidebar.scss';
@import './assets/scss/_header.scss';
@import './assets/dashIcons.scss';
@import './assets/scss/lightMode.scss';
html,
body,
#root {
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* //fix issue of hover on chrome autofill inputes */
input:-webkit-autofill::first-line {
  font-size: 16px;
}
@media (max-width: 1200px) {
  body {
    width: auto;
  }
}
