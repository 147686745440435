.dash-sidebar-menu-item {
  &.active {
    background: $dash-light-grey1;
    &.MuiListItemIcon-root {
      color: $dash-grey1;
    }
    span {
      color: $dash-grey1;
    }
    .dash-sidebar-menu-icon {
      color: $dash-grey1;
    }
  }
  .dash-sidebar-menu-icon {
    color: #fff;
    min-width: 36px;
    svg {
      font-size: 26px;
    }
  }
}
