form .error {
  color: rgb(247, 70, 70);
  font-size: 16px;
  margin-top: 10px;
  text-align: left;
}

.btn-outline-light:disabled {
  background-color: #5284ab !important;
  border: 1px solid #383838 !important;
  color: #fff;
  box-shadow: none !important;
  outline: unset !important;
  cursor: not-allowed;
  opacity: 0.65;
}
.dash-register-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 20%;
  min-height: 100vh;

  &.dash-register-container--individual {
    background-image: url(../../assets/img/Register-individual.jpg);
    background-size: initial;
    background-position: center center;
  }
  &.dash-register-container--entity {
    background-image: url(../../assets/img/Register-entity.jpg);
    background-size: initial;
    background-position: center center;
  }
  .dash-register-content {
    background-color: rgba(0, 0, 0, 0.78);
    border: 1px solid $dash-grey1;
    border-radius: 15px;
  }
  .dash-register-title {
    text-align: center;
    font-size: 25px;
    font-weight: normal;
    text-transform: capitalize;
    color: #fff;
    margin-bottom: 10px;
  }
  .dash-form-label {
    font-size: 18px;
    color: #fff;
    padding: 0;
  }
}
.dash-verify-phone-container {
  background-image: url(../../assets/img/VerifyPhone.jpg);
}
.dash-verify-email-container {
  background-image: url(../../assets/img/VerifyEmail.jpg);
}
.dash-change-password-container {
  background-image: url(../../assets/img/changePassword.jpg);
}
.dash-forgot-password-container {
  background-image: url(../../assets/img/forgotPassword.jpg);
}
