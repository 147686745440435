.dash-message-view {
  background-color: $dash-light-grey1;
  margin-bottom: 15px;
  .dash-message-left {
    border-right: 1px dashed $dash-grey3;
    height: auto;
    cursor: pointer;
    * {
      cursor: pointer;
    }
    .dash-message-left-label {
      color: $dash-grey2;
      font-size: 16px;
      font-weight: 600;
    }
    .dash-message-left-text {
      color: $dash-grey1;
      font-size: 14px;
    }
  }
  .dash-message-right {
    .dash-message-avatar {
      height: 60px;
      width: 60px;
      border-radius: 8px;
      background-color: $dash-grey1;
    }

    .dash-message-container {
      background-color: #fff;
      min-height: 90px;
      .dash-message-author {
        color: $dash-accent;
        font-size: 16px;
      }
      .dash-message-date {
        color: $dash-grey1;
        font-size: 14px;
      }
      .dash-message-content {
        color: $dash-grey1;
      }
      .dash-message-reciever {
        background-color: $dash-blue2;
        padding: 2px 10px;
        border-radius: 5px;
        color: #fff;
        margin-right: 5px;
      }
    }
  }
}
.dash-chat-container {
  background-color: $dash-grey6;
  border-radius: 10px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  line-height: 24px;
}
.dash-chat-body {
  background-color: $dash-grey3;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid $dash-black;
  color: #fff;
  margin-left: -15px;
  // &::after {
  //   content: ' ';
  //   position: absolute;
  //   width: 0;
  //   height: 0;
  //   left: 72px;
  //   right: auto;
  //   top: -28px;
  //   bottom: auto;
  //   border: 21px solid;
  //   border-color: transparent transparent $dash-grey3 $dash-grey3;
  // }
}
.dash-chat-body_currentUser {
  // &::after {
  //   left: auto;
  //   right: 76px;
  //   border-color: transparent $dash-grey3 $dash-grey3 transparent;
  // }
  margin-right: -10px;
}
.dash-message-date {
  color: $dash-light-grey1;
  font-weight: normal;
  font-size: 14px;
}
.dash-message-avatar_chat {
  width: 50px !important;
  height: 50px !important;
}
.dash-user-img-container {
  margin-top: -20px;
}
