.dash-bg {
  background-color: $dash-grey1;
}
.cursor-pointer {
  cursor: pointer;
}
.overflow-visible {
  overflow: visible !important;
}
.w-40 {
  width: 40px;
}
.dash-font-size-26 {
  font-size: 26px;
}
.dash-font-size-22 {
  font-size: 22px;
}
.box-shadow-focus-none {
  &:focus {
    box-shadow: none !important;
  }
}
.outline-focus-unset {
  &:focus {
    outline: unset !important;
  }
}
.text-dark-red {
  color: $dash-dark-red;
}
.dash-word-break-all {
  word-break: break-all;
}
.dash-left-3 {
  left: 1rem;
}
.dash-top-3 {
  top: 1rem;
}
// gg
