.dash-tasks-status-card {
  min-height: 100px;
  .card-header {
    color: #fff;
    font-size: 18px;
    padding: 7px;
  }
  .dash-tasks-status-item {
    font-size: 16px;
    color: $dash-grey1;
    .dash-tasks-status-title {
      width: calc(100% - 19px);
      margin-bottom: 0;
      cursor: pointer;
    }
  }
  .dash-grid-avatar {
    position: relative !important;
    overflow: hidden !important;
     margin-top: -3px;
  }
}
.dash-tasks-status-card--green {
  position: relative;
  .card-header {
    background-color: $dash-status-green;
  }
  background-color: $dash-status-green-body;

  &:after {
    content: "\f0ae";
    font-family: "Font Awesome\ 5 Free";
    right: 10px;
    position: absolute;
    bottom: -7px;
    color: $dash-status-green;
    font-size: 45px;
    opacity: 0.5;
    font-weight: 900;
  }
}
.dash-tasks-status-card--orange {
  .card-header {
    background-color: $dash-status-orange;
  }

  background-color: $dash-status-orange-body;

  &:after {
    content: "\f28b";
    font-family: "Font Awesome\ 5 Free";
    right: 10px;
    position: absolute;
    bottom: -7px;
    color: $dash-status-orange;
    font-size: 45px;
    opacity: 0.5;
  }
}
.dash-tasks-status-card--blue {
  .card-header {
    background-color: $dash-status-blue;
  }
  background-color: $dash-status-blue-body;

  &:after {
    content: "\f144";
    font-family: "Font Awesome\ 5 Free";
    right: 10px;
    position: absolute;
    bottom: -7px;
    color: $dash-status-blue;
    font-size: 45px;
    opacity: 0.5;
  }
}

.dash-tasks-status-card--gray {
  .card-header {
    background-color: $dash-status-gray;
  }
  background-color: $dash-status-gray-body;

  &:after {
    content: "\f058";
    font-family: "Font Awesome\ 5 Free";
    right: 10px;
    position: absolute;
    bottom: -7px;
    color: $dash-status-gray;
    font-size: 45px;
    opacity: 0.5;
    font-weight: 900;
  }
}
.dash-tasks-status-card--pink {
  .card-header {
    background-color: $dash-status-pink;
  }
  background-color: $dash-status-pink-body;

  &:after {
    content: "\f28b";
    font-family: "Font Awesome\ 5 Free";
    right: 10px;
    position: absolute;
    bottom: -7px;
    color: $dash-status-pink;
    font-size: 45px;
    opacity: 0.5;
    font-weight: 900;
  }
}
.dash-tasks-status-card--red {
  position: relative;
  .card-header {
    background-color: $dash-status-red;
  }
  background-color: $dash-status-red-body;

  &:after {
    content: "\f058";
    font-family: "Font Awesome\ 5 Free";
    right: 10px;
    position: absolute;
    bottom: -7px;
    color: $dash-status-red;
    font-size: 45px;
    opacity: 0.5;
  }
}
.dash-task-due-date {
  .dash-tasks-status-card--red {
    &:after {
      content: "\f7e4";
      font-weight: 900;
    }
  }
  .dash-tasks-status-card--blue {
    &:after {
      content: "\f01c";
      font-weight: 900;
    }
  }
  .dash-tasks-status-card--green {
    &:after {
      content: "\f251";
      font-weight: 900;
    }
  }
  .dash-tasks-status-card--orange {
    &:after {
      content: "\f2f2";
      font-weight: 900;
    }
  }
}
