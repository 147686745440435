.dash-user-card-left-side {
  border-right: 1px dashed;
}
.dash-user-card-popover {
  max-width: 100%;
  background-color: $dash-grey6;
  color: #fff;
  z-index: 1300;
  .arrow {
    &:after {
      border-top-color: $dash-grey6;
    }
  }
}
.dash-user-card-left-side {
  .dash-profile-user-img {
    width: 100px !important;
    height: 100px !important;
    margin: 0 auto;
  }
}
