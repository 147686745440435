// scss-lint:disable all
.animated {
  animation-duration: 1s;
  // animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

.btn {
  border-radius: 3px;
}

.btn-xs {
  padding: 1px 6px;
  line-height: 1.5;
}

.btn-search {
  color: #fff;
  background-color: #20a8d8;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #1b8eb7;
  }
}

.btn-reset {
  color: #fff;
  background-color: #67809f;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #578ebe;
  }
}

.btn-save {
  color: #fff;
  background-color: #26c281;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #20a46d;
  }
}

.btn-cancel {
  color: #fff;
  background-color: #bbb;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #aaa;
  }
}

.btn-close {
  color: #fff;
  background-color: #ccc;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #999;
  }
}

.btn-add {
  color: #fff;
  background-color: #ffc107;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #e0a800;
  }
}

.btn-edit {
  color: #fff;
  background-color: #26c281;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #20a46d;
  }
}

.btn-delete {
  color: #fff;
  background-color: #d91e18;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #b91a14;
  }
}

.btn-csv {
  color: #fff;
  background-color: #e87e04;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #c56b03;
  }
}

.btn-activate {
  color: #fff;
  background-color: #26c281;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #20a46d;
  }
}

.btn-deactivate {
  color: #fafcfb;
  background-color: #bfbfbf;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #adadad;
  }
}

.btn-clone {
  color: #fff;
  background-color: #ffc107;
  &:hover,
  &:focus,
  &:active,
  &.active {
    background-color: #e0a800;
  }
}

.btn-group {
  .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .btn:last-child:not(:first-child),
  .dropdown-toggle:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    opacity: 0.8;
  }

  .dropdown-menu {
    min-width: 7rem !important;
    left: 0 !important;
  }

  .dropdown-item {
    padding: 8px 5px !important;
  }
}

.board {
  .lane {
    border-width: 1px;
    border-style: solid;
    display: flex;
    flex-direction: column;
    border-color: #636c72;
    padding: 40px;

    background-color: #636c72;

    .header {
      padding: 40px;

      background-color: #636c72;
    }
  }
}



.magic-border {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 6px;
  outline: none;
  height: 50px;
  width: 150px;
  background-color: #636c72;
}

.input-group {
  width: auto;
}

.lane-wrapper {
  display: block;
  width: 270px;
  margin-right: 15px;
}

.pull-right {
  margin-left: 80%;
}

.text-table {
  margin-right: 90%;
}

.btn-w-m {
  min-width: 120px;
}

.datepicker-s {
  min-width: 450%;
}

.datepicker-s2 {
  min-width: 165%;
}

.margin-separator {
  margin-right: 5px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.margin-separator2 {
  margin-right: 20px;
}

.margin-separator-top2 {
  margin-top: 2px;
}
.margin-separator-top-search{
  margin-top:50px;
}
.margin-separator-top3 {
  margin-top: 20px;
}

.margin-separator-top {
  margin-top: 50px;
}

.margin-separator-bot {
  margin-bottom: 10px;
}

.label-info,
.badge-info {
  background-color: #23c6c8;
  color: #ffffff;
}

.label {
  background-color: #d1dade;
  color: #5e5e5e;
  font-family: "Open Sans";
  font-size: 10px;
  font-weight: 600;
  padding: 3px 8px;
  text-shadow: none;
}

.ibox {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}

.message-avatar {
  height: 48px;
  width: 48px;
  border: 1px solid #e7eaec;
  border-radius: 4px;
  margin-top: 1px;
}

.float-e-margins {
  margin-bottom: 5px;
}

.ibox.collapsed .ibox-content {
  display: none;
}

.ibox-title {
  background-color: #fff;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 3px 0 0;
  color: inherit;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px;
}

.ibox-title,
.ibox-content {
  border-width: 1px;
}

.ibox-footer {
  color: inherit;
  border-top: 1px solid #e7eaec;
  font-size: 90%;
  background: #ffffff;
  padding: 10px 15px;
}

.ibox-content {
  background-color: #fff;
  color: inherit;
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}

@mixin clearfix() {
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}

.input-group-addon {
  min-width: 40px;
  white-space: nowrap;
  vertical-align: middle; // Match the inputs
}

.width-25 {
  width: 25px !important;
}
.width-40 {
  width: 40px !important;
}
.width-50 {
  width: 50px !important;
}
.width-60 {
  width: 60px !important;
}
.width-75 {
  width: 75px !important;
}
.width-100 {
  width: 100px !important;
}
.width-125 {
  width: 125px !important;
}
.width-150 {
  width: 150px !important;
}
.width-175 {
  width: 175px !important;
}
.width-200 {
  width: 200px !important;
}
.width-250 {
  width: 250px !important;
}
.width-300 {
  width: 300px !important;
}
.width-700 {
  width: 700px !important;
}

.margin0-top {
  margin-top: 0 !important;
}
.margin0-bottom {
  margin-bottom: 0 !important;
}
.margin0-left {
  margin-left: 0 !important;
}
.margin0-right {
  margin-right: 0 !important;
}
.margin0-height {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.margin0-width {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.margin0-all {
  margin: 0 !important;
}

.margin5-top {
  margin-top: 5px !important;
}
.margin5-bottom {
  margin-bottom: 5px !important;
}
.margin5-left {
  margin-left: 5px !important;
}
.margin5-right {
  margin-right: 5px !important;
}
.margin5-height {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.margin5-width {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.margin5-all {
  margin: 5px !important;
}

.height-100pct {
  height: 100%;
}
.height-100 {
  height: 100px !important;
}
.size-100 {
  width: 100px !important;
  height: 100px !important;
}
.size-75 {
  width: 75px !important;
  height: 75px !important;
}
.size-50 {
  width: 50px !important;
  height: 50px !important;
}
.size-40 {
  width: 40px !important;
  height: 40px !important;
}
.size-25 {
  width: 25px !important;
  height: 25px !important;
}
