.table {
  thead {
    background-color: $dash-grey1;
    min-height: 49px;
    tr {
      background-color: $dash-grey1;
      th {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        border-bottom: 1px solid $dash-grey6;
        border-top: 1px solid $dash-grey6;
      }
    }
  }
  tbody {
    tr {
      td {
        color: #fff;
        font-size: 14px;
        border-top: 1px solid $dash-grey6;
        border-left: none;
      }
    }
  }
}
tr:nth-child(even) {
  background-color: $dash-grey4;
}
tr:nth-child(odd) {
  background-color: $dash-grey5;
}
