body {
  font-family: "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
.dash-form-control {
  height: 34px;
  border: 1px solid $dash-light-grey1;
  border-radius: 9px;
}
.dash-form-label {
  color: #fff;
  font-size: 19px;
  font-family: "Segoe UI";
  line-height: 27px;
  text-align: right;
}
.dash-vertical-divider {
  border-left: 1px solid $dash-grey1;
  height: 190px;
  display: flex;
  align-self: center;
}
.dash-primary-button {
  font-size: 15px;
  background-color: $dash-blue1;
  color: #fff;
  border: 1px solid $dash-grey2;
  border-radius: 9px !important;
  min-width: 105px;
  height: 34px;
  padding-top: 4px;
  &:hover,
  &:focus,
  &:active {
    background-color: $dash-accent !important;
    border: 1px solid $dash-grey2 !important;
    color: #fff;
    box-shadow: none !important;
    outline: unset !important;
  }
  &:disabled,
  &[disabled] {
    &,
    * {
      opacity: 0.7;
      cursor: not-allowed;
      pointer-events: none;
      &:hover {
        background-color: $dash-blue1 !important;
      }
    }
  }
}
.dash-primary-dropdown-button {
  > button.btn-primary.dropdown-toggle {
    @extend .dash-primary-button;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none !important;
    }
    &:after {
      vertical-align: 0.055em;
    }
  }
}
.dash-secondary-button {
  font-size: 15px;
  background-color: $dash-grey3;
  color: #fff;
  border: 1px solid $dash-grey2;
  border-radius: 9px !important;
  min-width: 105px;
  height: 34px;
  padding-top: 3px;
  &:hover,
  &:focus,
  &:active {
    background-color: $dash-grey1 !important;
    border: 1px solid $dash-grey2 !important;
    color: #fff;
    box-shadow: none !important;
    outline: unset !important;
  }
}
.dash-secondary-dropdown-button {
  > button.btn-primary.dropdown-toggle {
    @extend .dash-secondary-button;
    &:hover,
    &:focus,
    &:active {
      box-shadow: none !important;
    }
    &:after {
      vertical-align: 0.055em;
    }
  }
}
.dash-card-header {
  background-color: $dash-grey1 !important;
  .dash-card-title {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    color: #fff;
    font-family: "Segoe UI";
  }
  svg.dash-card-title {
    width: 16px;
  }
  svg {
    color: #fff;
  }
}
.dash-card-footer {
  background-color: $dash-grey1;
}
.react-datepicker-wrapper {
  width: 100%;
}
.dash-card-subsection-title {
  color: #fff;
  text-align: center;
  margin-bottom: 15px;
}
.dash-card-body {
  margin-bottom: 0px;
  background-color: $dash-grey5;
}
.dash-card {
  border: 1px solid $dash-grey6;
}
.dash-edit-button {
  font-size: 15px;
  background-color: $dash-blue2;
  color: #fff;
  border: 1px solid $dash-blue2;
  min-width: 60px;
  height: 29px;
  padding-top: 2px;
  a {
    color: #fff;
    text-decoration: none;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: $dash-blue2 !important;
    border: 1px solid $dash-blue2 !important;
    color: #fff;
    box-shadow: none !important;
    outline: unset !important;
  }
}
.dash-datepicker {
  &:before {
    content: "\f073";
    font-family: "Font Awesome\ 5 Free";
    top: 5px;
    color: $dash-grey1;
    right: 22px;
    position: absolute;
    font-size: 16px;
    z-index: 1;
  }
  width: 100%;
}
.dash-grid-button {
  font-size: 15px;
  background-color: $dash-blue1;
  color: #fff;
  border: 1px solid $dash-blue1;
  min-width: 60px;
  height: 29px;
  padding-top: 2px;
  border-radius: 6px !important;
  cursor: pointer;
  a {
    color: #fff;
    text-decoration: none;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: $dash-blue1 !important;
    border: 1px solid $dash-blue1 !important;
    color: #fff;
    box-shadow: none !important;
    outline: unset !important;
    -webkit-filter: brightness(150%);
    filter: brightness(150%);
  }
}
.dash-delete-button {
  font-size: 15px;
  background-color: $dash-red;
  color: #fff;
  border: 1px solid $dash-red;
  min-width: 60px;
  height: 29px;
  padding-top: 2px;
  a {
    color: #fff;
    text-decoration: none;
  }
  &:hover,
  &:focus,
  &:active {
    background-color: $dash-red !important;
    border: 1px solid $dash-red !important;
    color: #fff;
    box-shadow: none !important;
    outline: unset !important;
  }
}
.dash-page-content {
  background: $dash-grey3;
  width: 100%;
  min-height: 100%;
}
.dash-tabs {
  background-color: $dash-grey1;
  border: 1px solid $dash-grey6;
  a.nav-link {
    min-width: 150px;
    font-weight: 600;
    text-align: center;
    font-size: 16px;
    color: #fff;
    &.active {
      background-color: #fff;
      color: $dash-grey1;
    }
  }
}
.dash-tab {
  padding-top: 20px;
  background-color: $dash-grey5;
  min-height: 300px;
  .dash-icon-badge {
     position: relative !important;
    // overflow:hidden;
    float: right;
    z-index: 1;
    margin-bottom: 5px;
    top: 0px;
    padding: 3px 6px;
    font-size: 12px;
  }
}
.dash-show-message-button {
  background: transparent;
  padding: 2px;
  height: 29px;
  img {
    width: 35px;
  }
  i {
    font-size: 22px;
  }
  svg {
    font-size: 22px;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
}
.dash-icon-badge {
  position: absolute;
  color: #fff;
  border-radius: 50px;
  background-color: $dash-red;
  right: 10px;
  top: -7px;
  padding: 4px 7px 2px 7px;
  font-size: 14px;
  &.dash-icon-badge_message {
    right: 24px;
    top: -3px;
  }
  &.dash-icon-badge_message_grid {
    right: -2px;
    top: -7px;
    padding: 3px 6px;
    font-size: 12px;
  }
  &.dash-icon-badge_calender {
    right: 1px;
    top: 2px;
    padding: 3px 6px;
    font-size: 12px;
  }
}
.dash-checkbox {
  .dash-checkbox-label {
    padding-top: 2px;
    font-size: 16px;
    color: $dash-light-grey1;
  }
}
.modal-dialog {
  top: 100px !important;
  //min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  //overflow: hidden;

  @media (max-width: 768px) {
    min-height: calc(100vh - 20px);
  }
}
.dash-button-spinner {
  border-width: 0.1rem;
}
.dash-form-body,
.dash-grid-body {
  position: relative;
}
.dash-page-spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 10;
  .col {
    display: flex;
    justify-content: center;
    padding-top: 150px;
    .dash-page-spinner {
      width: 5rem;
      height: 5rem;
      color: $dash-accent;
    }
  }
}
.dash-error-message {
  color: $dash-dark-red;
  font-size: 14px;
  font-weight: bold;
  top: 2px;
  position: relative;
}
.dash-require-astrike {
  &:before {
    content: "\002A";
  }
  color: $dash-dark-red;
  font-size: 20px;
  position: sticky;
  top: -5px;
  font-weight: 900;
}

table {
  text-align: left;
  position: relative;
}
tbody{
  overflow-y: scroll;
}
.channelCounter{
  overflow: -moz-hidden-unscrollable;
}
td{
  display:hidden;
}
th {
  background: #333333;
  position: sticky;
  overflow: visible;
  top: 0;
}
.th-click:hover{
  background: #595959;
  cursor: pointer;
}
.tasks-color-icon{
  color: #fff;
}
.overflow-hidden{
  overflow:hidden;
}
.thead{
  display:table;
width:98.7%;
table-layout:relative;
}

.dash-confirm-modal-body {
  color: $dash-grey2;
  font-size: 20px;
}
a.dash-confirm-modal-actions {
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}
a.dash-confirm-modal-actions_Yes {
  color: $dash-accent;
}
a.dash-confirm-modal-actions_No {
  color: $dash-dark-red;
}
.dash-grid-header {
  color: #fff;
}
.highcharts-text-outline {
  stroke: none;
}
.dash-status-badge_open {
  background-color: $dash-status-blue;
}
.dash-status-badge_inProgress {
  background-color: $dash-status-green;
}
.dash-status-badge_blocked {
  background-color: $dash-status-pink;
}
.dash-status-badge_done {
  background-color: $dash-status-gray;
}
.dash-new-chat-icon {
  display: inline-block;
  border-radius: 60px;
  padding: 0.5em 0.6em;
  background-color: $dash-black;
  cursor: pointer;
  i {
    color: #fff;
    font-size: 16px;
  }
}
//user image in profile
.dash-profile-user-img {
  width: 150px !important;
  height: 150px !important;
}
//profile user details
.dash-profile-user-details {
  background-color: $dash-grey6;
  padding: 10px;
  border: 1px solid $dash-grey2;
  border-radius: 10px;
  p {
    color: #fff;
    font-size: 19px;
    font-family: "Segoe UI";
    line-height: 27px;
    margin-bottom: 0;
  }
}
.dash-profile-inputs-container {
  margin-left: -35px;
  .dash-datepicker:before {
    right: 60px;
  }
}
.dash-startChat-icon{
  overflow: inherit;
}
.dash-grid-overflow{
  // position: inherit !important;
  overflow: hidden !important;
}
.dash-grid-avatar { 
   width: 28px !important;
  height: 28px !important;
  // float: left;
  margin-right: 10px;
  position: inherit !important;
  overflow: hidden !important;
}
.table-header{
  overflow: visible;
}
.tbody-fx{
	content: ".";
	height: 0;
	clear: both;
}

.dash-tooltip {
  font-size: 14px !important;
  background-color: $dash-grey1 !important;
}
.dash-tooltip-arrow {
  color: $dash-grey1 !important;
}
.dash-disabled-wrapper {
  cursor: not-allowed;
}
.dash-disabled,
.dash-disabled {
  pointer-events: none;
}
.modal-content {
  margin-bottom: 50px;
}
.dash-email-link {
  color: #fff;
  text-decoration: underline;
}
.modal-backdrop {
  width: 100%;
  height: 100%;
}
.dash-export-button {
  background-color: #333e5a;
  color: #fff;
}
.modal {
  overflow: hidden;
  .dash-card-header {
    display: inline-block;
    width: 100%;
  }
  .dash-card {
    .modal-header {
      border-bottom: none;
    }
    .modal-body {
      max-height: 80vh; /* max 80% of the viewport height */
      overflow-y: auto;
      // padding-bottom: 100px;
    }
  }
}
.modal-open {
  .modal {
    overflow: hidden !important;
  }
}
.dash-confirm-modal {
  .modal-dialog {
    min-height: 100vh;
  }
}
.dash-back-button {
  font-size: 15px;
  height: 34px;
  padding: 0px 10px !important;
  margin-bottom: 5px;
}
.button-title {
  margin: auto;
  text-align: center;
}
.separator-top {
  margin-top: 30px;
}
.react-datepicker-popper,
.calendar-popout {
  z-index: 111119999 !important;
}
.modal-dialog-scrollable .modal-content {
  overflow: inherit;
}
@media (min-width: 576px) {
  .modal-dialog-scrollable {
    max-height: calc(100% - 70px) !important;
    transform: none !important;
  }
}
.dash-line-icon {
  box-sizing: border-box;
  position: relative;
  text-align: center;
  background-image: url(../../assets/img/logo-seperator.png);
  background-repeat: no-repeat;
  background-position: center center;
  font-size: 0;
  height: 20px;
}
.dash-line-icon::before,
.dash-line-icon::after {
  box-sizing: border-box;
  width: 50px;
  height: 1px;
  border-top: 1px solid #fff;
  display: inline-block;
  content: "";
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  top: 50%;
  -moz-transform: translatey(-50%);
  -ms-transform: translatey(-50%);
  -o-transform: translatey(-50%);
  -webkit-transform: translatey(-50%);
  transform: translatey(-50%);
}
.dash-task-calender {
  margin-left: -36px;
}
.dash-switch {
  .Mui-checked {
    color: $dash-blue1 !important;
    & + .MuiSwitch-track {
      background-color: $dash-blue1 !important;
    }
  }
}
.dash-page-title {
  color: #fff;
}
.width-5 {
  width: 5px;
}
.dash-icons-white {
  &:before {
    color: #fff;
  }
}
.dash-dropzone {
  min-height: 100px;
  border: 2px dashed;
  text-align: center;
  display: table;
  width: 100%;
  background-color: $dash-grey1;
  color: #fff;
  .dash-dropzone-text {
    display: table-cell;
    vertical-align: middle;
    font-size: 20px;
  }
}
.dash-dropzone-selected {
  color: #fff;
  li.list-group-item {
    font-size: 16px;
    background-color: $dash-grey1;
    color: #fff;
    padding: 8px 18px;
    padding-top: 15px;
    i {
      font-size: 20px;
      color: #fff;
    }
  }
}
.dash-appBar {
  background-color: $dash-grey1 !important;
}
.dash-refresh-button {
  padding: 6px;
  height: 40px;
  svg {
    font-size: 22px;
  }
}
.pg-viewer-wrapper{
  text-align: center;
  padding-top: 70px;
}
.dash-middle-section-height{
height: 5.5rem;
}