.dash-header-main {
  flex: 1;
}
.dash-header-container {
  z-index: 1300 !important;
  background-color: $dash-grey2;
}
.dash-header-logo {
  width: 200px;
  height: 63px;
  background-image: url(../../assets/img/header-logo.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.dash-header-more-menu {
  position: absolute;
  right: 0;
  color: #fff;
  display: contents;
  &:focus {
    outline: unset !important;
    box-shadow: none;
  }
  svg {
    font-size: 30px;
  }
}
.dash-header-menu-item {
  position: relative;
  margin-right: 4px;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  .dash-header-menu-text {
    display: block;
    color: $dash-light-grey1;
    font-weight: normal;
    text-transform: capitalize;
  }
  .dash-header-menu-icon {
    &:before {
      color: $dash-light-grey1;
    }
    font-size: 33px;
  }
  &.active {
    .dash-header-menu-text {
      color: $dash-blue4;
      font-weight: 600;
    }
    .dash-header-menu-icon {
      &:before {
        color: $dash-blue4;
        font-weight: 600;
      }
    }
  }

  &:hover {
    text-decoration: none;
    .dash-header-menu-text {
      color: $dash-blue4;
      font-weight: 600;
    }

    .dash-header-menu-icon {
      &:before {
        color: $dash-blue4;
        font-weight: 600;
      }
    }
  }
  .dash-header-user-img {
    height: 35px;
    width: 35px;
  }
  .dash-header-menu-text {
    font-size: 15px;

    font-weight: 500;
    color: $dash-light-grey1;
    font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
      'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  }
}
.dash-header-user-role {
  color: #fff;
}
.dash-header-container--manyLinks {
  .dash-header-menu-item {
    margin-right: 2px;
  }
}