// scss-lint:disable all
.animated {
  animation-duration: 1s;
  // animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.hinge {
  animation-duration: 2s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.dash-entry-container {
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../../assets/img/login-back.png);
  background-position: center 20%;
}

.dash-entry-logo {
  width: 300px;
}
.dash-entry-card-group {
  background-color: $dash-light-grey1-opacity30;
  border: 1px solid $dash-grey1;
  border-radius: 17px;
  min-height: 370px;
  .dash-entry-card {
    background-color: transparent;
    border: none;
  }
}
.dash-entry-title {
  color: $dash-blue1;
  font-weight: 500;
}
.dash-entry-sub-title {
  color: $dash-blue1;
  font-size: 15px;
}
.dash-entry-signup-text {
  color: $dash-accent;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
}

// .totalSize {
//   height: 100%;
//   position: absolute;
//   left: 0;
//   top: 0;
//   width: 100%;
// }
