.dash-calendar {
  .rbc-event-content {
    text-transform: capitalize;
  }

  .rbc-show-more {
    background-color: transparent;
    text-align: center;
    font-size: 14px;
  }

  .rbc-toolbar button {
    background-color: $dash-black;
    color: #fff;
    border: none;
    border-radius: 0;
    height: 34px;

    &.rbc-active,
    &:hover {
      background-color: $dash-accent;
      color: #fff;
    }

    &:not(:last-child) {
      margin-right: 6px;
    }
  }

  .rbc-toolbar-label {
    color: #fff;
  }

  .rbc-toolbar button.rbc-active:hover,
  .rbc-toolbar button.rbc-active:focus,
  .rbc-toolbar button:hover,
  .rbc-toolbar button:focus {
    background-color: $dash-accent;
    color: #fff;
    border: none;
    box-shadow: none;
    outline: none;
  }

  .rbc-header,
  .rbc-time-gutter.rbc-time-column {
    background-color: $dash-black;
    color: #fff;
  }
  .dash-calender-event-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 125px;
  }
  .dash-calender-event-icon {
    font-size: 14px;
    margin-right: 2px;
    position: relative;
  }
  .rbc-date-cell {
    a {
      color: $dash-light-grey1;
    }
    &.rbc-off-range {
      a {
        color: $dash-grey1;
      }
    }
  }
}
