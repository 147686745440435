.section {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2), 0 1px 0 #fff;
  background-color: #fff;
  border: 0.5px solid #ccc;
  position: relative;
  display: inline-block;
  width: 50%;
  text-shadow: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  margin-bottom: 3px;
  padding: 3px;
}

.section2 {
  background-color: #fff;
  border: 0.5px solid #ccc;
  position: relative;
  display: inline-block;
  width: 50%;
  text-shadow: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  margin-bottom: 3px;
  padding: 3px;
}

.bg-colorWhite2 {
  background-color: #f5f6fa;
}

.margin-divider {
  margin-bottom: 10px;
}
.highcharts-background {
  fill: #999999;
}
/* .highcharts-axis-line {
  stroke: #333333;
}
.highcharts-yaxis-grid path {
  stroke: #333333;
} */
